// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-content-detail-page-js": () => import("./../../../src/templates/contentDetailPage.js" /* webpackChunkName: "component---src-templates-content-detail-page-js" */),
  "component---src-templates-content-homepage-js": () => import("./../../../src/templates/contentHomepage.js" /* webpackChunkName: "component---src-templates-content-homepage-js" */),
  "component---src-templates-content-issues-listing-js": () => import("./../../../src/templates/contentIssuesListing.js" /* webpackChunkName: "component---src-templates-content-issues-listing-js" */),
  "component---src-templates-content-news-item-js": () => import("./../../../src/templates/contentNewsItem.js" /* webpackChunkName: "component---src-templates-content-news-item-js" */),
  "component---src-templates-content-news-listing-js": () => import("./../../../src/templates/contentNewsListing.js" /* webpackChunkName: "component---src-templates-content-news-listing-js" */),
  "component---src-templates-content-policy-detail-page-js": () => import("./../../../src/templates/contentPolicyDetailPage.js" /* webpackChunkName: "component---src-templates-content-policy-detail-page-js" */)
}

